<template>
  <div id="app">
    <Index></Index>
  </div>
</template>

<script>
import Index from "./views/Index";
export default {
  components: {
    Index,
  },
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
</style>
