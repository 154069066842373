<template>
  <div class="nav-wrapper" :style="style">
    <div class="logo">
      <img class="logo-img" :src="logoUrl" alt="爱鸿森" />
    </div>
    <el-menu class="nav" :defaultActive="path" mode="horizontal">
      <el-menu-item class="nav-item" @click="handleSelect('/')" index="/"
        >主页<span class="inner"></span></el-menu-item
      >
      <el-menu-item
        class="nav-item"
        @click="handleSelect('/detail')"
        index="/detail"
        >关于我们<span class="inner"></span></el-menu-item
      >
      <el-menu-item class="nav-item" @click="handleLink"
        >投资者关系<span class="inner"></span></el-menu-item
      >
      <el-menu-item
        class="nav-item"
        @click="handleSelect('/contact')"
        index="/contact"
        >联系我们<span class="inner"></span></el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoUrl: require("@/assets/images/logo.png"),
      scrollY: 0,
      path: "/",
    };
  },
  computed: {
    style() {
      return `background-color: rgba(64, 96, 253, ${this.scrollY / 240});`;
    },
  },
  watch: {
    $route: "handleRoute",
  },
  created() {
    this.listenerFunction();
  },
  destroyed() {
    this.stopListenerFunction();
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction(e) {
      document.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      this.scrollY = window.pageYOffset;
    },
    handleSelect(path) {
      if (this.path == path) return;
      this.path = path;
      this.$router.push(path);
    },
    handleLink() {
      window.location.assign("http://www.senmiaotechir.com");
    },
    handleRoute(to, from, next) {
      this.path = this.$route.path;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 80px;
  .logo {
    padding-top: 10px;
  }
  .nav {
    display: flex;
    border-bottom: none;
    background-color: rgba(64, 96, 253, 00);
    .nav-item {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      background-color: transparent;
      color: #ffffff;
      border-color: transparent;
      user-select: none;
      position: relative;
      // text-shadow: 0px 0px 3px #000000;
      &:not(.is-disabled):focus {
        background-color: transparent;
        color: #ffffff;
      }
      &:hover,
      &.is-active {
        .inner{
          border-bottom: 2px solid #ffffff;
        }
      }
      .inner{
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        width: 24px;
        height: 100%;
        border-bottom: 2px solid transparent;
      }
    }
    &:hover {
      .nav-item.is-active {
        .inner{
          border-bottom: 2px solid transparent;
        }
      }
      .nav-item.is-active:hover {
        .inner{
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
}
</style>
