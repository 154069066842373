import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import ElementUI from "element-ui";
//import "element-ui/lib/theme-chalk/index.css";
import { Menu, MenuItem,MenuItemGroup } from 'element-ui';
import animated from "animate.css";

Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.use(animated);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);

router.beforeEach((to, from, next) => {
  if (navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i)) {
    console.log("移动端");
    location.replace('http://m.ihongsen.com')
  } else {
    console.log("PC端");
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
