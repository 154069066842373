<template>
  <div class="footer">
    Copyright © 2018 SENMIAO TECHNOLOGY LIMITED. All rights reserved. 备案号：<span class="icp" @click="redirect">蜀ICP备13015483号-1</span>
  </div>
</template>

<script>
export default {
  methods:{
    redirect(){
      window.location.assign('http://beian.miit.gov.cn')
    }
  }
}
</script>

<style lang="less" scoped>
  .footer{
    background-color: #F3F3F3;
    color: #181818;
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    width: 100%;
    .icp{
      color: rgb(85, 85, 85);
      font-weight: bold;
    }
  }
</style>