import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/Home.vue"),
    meta: { title: "森淼科技 - 深耕汽车出行与科技服务" },
  },
  {
    path: "/detail",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../components/Detail.vue"),
    meta: { title: "森淼科技 - 关于我们" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../components/Contact.vue"),
    meta: { title: "森淼科技 - 联系我们" },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
