<template>
  <div id="page">
    <Navigation class="nav"></Navigation>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
    >
      <router-view />
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  components: {
    Navigation,
    Header,
    Footer,
  },
};
</script>

<style lang="less" scoped>
#page {
  width: 100%;
  font-family: "微软雅黑";
  overflow: hidden;
  background: linear-gradient(180deg, rgb(64, 96, 253),rgb(64, 96, 253)) no-repeat center top /
    100% 80px;
  .nav {
    position: fixed;
    top: 0;
    z-index: 10;
  }
}
</style>
